@import "./0_mediaquery";
@import "./1_variables";

.NewInstancesPage {
  overflow-y: auto;
  height: 100%;

  .model {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 9999;
    display: flex;

    .container {
      max-width: 30vw;
      width: 100%;
      height: fit-content;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      display: block;
      padding: 1em 2em 1em 2em;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        margin: 1em 0 1em 0;
        color: #74465c;
        text-align: center;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        color: #74465c;
        margin: 12px 0 12px 0;
        cursor: pointer;
      }
      h4:hover {
        box-shadow: 1px 1px 5px #60606070;
      }

      i {
        margin-right: 1em;
      }
    }

    .alert {
      max-width: 30vw;
      width: fit;
      height: fit-content;
      margin: auto auto 50vh auto;
      background-color: white;
      border-radius: 5px;
      display: block;
      padding: 1em 2em 1em 2em;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        color: #74465c;
        margin: 12px 0 12px 0;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        background-color: $primary;
        color: white;
        margin: 1em 0 12px 0;
        cursor: pointer;
        text-align: center;
      }

      h4:hover {
        background-color: rgba($color: $primary, $alpha: 0.7);
      }
    }
  }

  .top_panel {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: white;
    border-radius: 5px;
    padding: 3px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 5px;
    border: 1px solid $accent;
    max-width: 350px;
    // width: 100%;

    .active {
      color: $accent;
    }

    p {
      padding: 4px 1em 4px 1em;
      cursor: pointer;
      text-align: center;
      font-size: small;
    }

    i {
      margin-right: 12px;
    }
  }

  .title_panel {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    background-color: $primary;
    border-radius: 0 0 6px 6px;
    border: 1px solid $accent;
    border-top: none;
    min-width: 200px;

    .active {
      color: $accent;
    }

    h3 {
      color: white;
      padding: 4px 1em 4px 1em;
      cursor: pointer;
      text-align: center;
      font-size: small;
    }

    i {
      margin-right: 12px;
    }
  }

  .right_panel {
    position: absolute;
    right: 10px;
    left: auto;
    top: 10px;
    bottom: 10px;
    height: fit-content;
    border-radius: 0 5px 5px 5px;

    .fa-angle-right,
    .fa-angle-left {
      padding: 8px 10px 8px 10px;
      background-color: $accent;
      cursor: pointer;
      position: absolute;
      top: 0px;
      left: -32px;
      width: 32px;
      color: white;
      border-radius: 5px 0 0 5px;
    }
    .fa-angle-right:hover,
    .fa-angle-left:hover {
      background-color: $accent;
    }
    .collapsible {
      // position: relative;
      // max-width: 400px;
      // width: 100%;
      // height: 100%;
      // background-color: $bg2;
      // overflow-y: hidden;

      height: 450px;
      position: relative;
      max-width: 400px;
      width: 100%;
      overflow: hidden !important;
      display: grid;
      grid-template-rows: auto 1fr;
      background-color: $bg2;

      .bar4 {
        background-color: $accent;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-radius: 0 5px 0 0;

        p {
          font-size: small;
          padding: 8px 10px 8px 10px;
          color: white;
          text-align: center;
          cursor: pointer;
        }

        p:hover {
          color: $accent;
          background-color: $bg2;
        }

        .active {
          font-size: small;
          padding: 8px 10px 8px 10px;
          color: $accent;
          background-color: $bg2;
          text-align: center;
          cursor: pointer;
        }
      }

      .bar3 {
        background-color: $accent;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-radius: 0 5px 0 0;

        p {
          font-size: small;
          padding: 8px 10px 8px 10px;
          color: white;
          text-align: center;
          cursor: pointer;
        }

        p:hover {
          color: $accent;
          background-color: $bg2;
        }

        .active {
          font-size: small;
          padding: 8px 10px 8px 10px;
          color: $accent;
          background-color: $bg2;
          text-align: center;
          cursor: pointer;
        }
      }

      .r_layers {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;
        overflow: hidden !important;
        padding: 10px;
        overflow: auto;

        hr {
          color: #74465c;
          margin: 10px 0 5px 0;
        }

        h4 {
          font-size: medium;
          color: $accent;
          font-weight: 600;
        }

        .scr {
          height: 100%;
          overflow-y: auto;

          h5 {
            margin: 5px 10px 5px 3em;
            font-size: x-small;
          }

          .theme {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 5px;
            margin: 5px 10px 5px 4em;
            font-size: x-small;
            font-style: italic;
            color: $accent;

            input {
              height: 16px;
              width: 16px;
              border: none;
              outline: none;
            }
          }

          .item {
            display: grid;
            grid-template-columns: auto auto 1fr auto auto;
            gap: 5px;
            margin: 10px 0 10px 0;
            font-size: small;

            .fa {
              padding: 2px;
              color: $accent;
              cursor: pointer;
            }
            .fa:hover {
              color: #74465c;
            }
          }
        }
      }

      .charts {
        padding: 1em;
        overflow: hidden;

        .scr {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 5px;

          button {
            border: none;
            padding: 5px 10px 5px 10px;
            cursor: pointer;
            color: white;
          }
          button:hover {
            background-color: #74465c;
          }
        }
        hr {
          color: #74465c;
          margin: 10px 0 5px 0;
        }

        h4 {
          font-size: medium;
          // margin-bottom: 1em;
          color: $accent;
          font-weight: 600;
          text-align: left;
        }

        .area {
          height: 100%;
          overflow-y: auto;
        }
      }

      .s_panel {
        padding: 1em;
        overflow-y: auto;
        height: 100%;

        label {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }

        hr {
          color: #74465c;
          margin: 10px 0 5px 0;
        }

        h4 {
          font-size: medium;
          font-weight: 600;
          text-align: left;
        }

        h5 {
          color: $dark;
          padding: 10px 0 5px 0;
        }

        .exp {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          font-size: small;
          cursor: pointer;
          margin: 1px 0 1px 0;
          padding: 5px;

          p {
            display: block;
            margin: auto;
            width: 100%;
          }
        }

        .exp:hover {
          box-shadow: 1px 1px 5px #60606030;
        }
      }
    }
  }

  .BottomPanel {
    position: absolute;
    left: 30%;
    right: 30%;
    bottom: 0;
    height: fit-content;
    z-index: 9999;

    .opts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background-color: rgba($color: $primary, $alpha: 0.4);
      border-radius: 5px 5px 0 0;

      h4 {
        color: white !important;
        font-weight: 200 !important;
        height: fit-content;
        text-align: center;
        padding: 8px 0 8px 0;
        cursor: pointer;
      }

      h4:hover {
        color: white !important;
        background-color: $accent;
        border-radius: 5px;
      }
    }

    .Popup {
      height: fit-content;
      z-index: 9999;
      background-color: rgba($color: white, $alpha: 0.9);
      min-height: 40vh;
      max-height: 65vh;
      border-radius: 8px;
      padding: 10px 1em 10px 1em;

      p {
        text-align: center;
        font-size: 10pt;
        padding: 0 0 5px 0;
      }

      i {
        color: $dark;
        display: block;
        width: fit-content;
        margin-left: auto;
        cursor: pointer;
      }
      i:hover {
        color: $accent;
      }

      .items {
        width: 100%;

        .input {
          margin: 10px 0 10px 0;

          label {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }
          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        .aoi {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;

          p {
            line-height: 44px;
            text-align: left !important;
          }
        }
        h3 {
          text-align: center;
          padding: 8px;
          color: $primary;
          width: 100%;
        }

        h4 {
          font-size: 10pt;
          text-align: center;
          padding: 10px 0 10px 0;
        }

        img {
          height: 44px;
          width: 44px;
          object-fit: contain;
        }

        .imgSelected {
          border: 2px solid $primary;
        }

        .list {
          padding: 1em;
        }

        .item {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          color: $dark;
          margin: 16px 0 16px 0;
        }
      }
    }
    @include maxwidth(mobile) {
      left: 17% !important;
      width: 63vw;
    }
  }

  .map {
    // max-width: 85%;
    // width: 100%;
    position: relative;
    box-shadow: 2px 2px 5px #60606040;
    margin: 2em auto;
    box-shadow: 0px 8px 16px #60606040;
    border-radius: 8px;
    overflow: hidden;

    .ol-zoom {
      position: absolute;
      top: auto;
      bottom: 44px;
      right: 10px;
      left: auto;
      * {
        color: white;
        cursor: pointer;
        background-color: $accent;
        font-size: medium;
      }
      *:hover {
        background-color: $primary;
      }
    }

    .ol-zoom-extent {
      position: absolute;
      top: auto;
      bottom: 10px;
      right: 10px;
      left: auto;

      // width: fit-content;
      // height: fit-content;

      * {
        color: white;
        cursor: pointer;
        background-color: $accent;
        font-size: medium;
      }
      *:hover {
        background-color: $primary;
      }
    }

    .ol-scale-bar {
      position: absolute;
      bottom: 10px;
      left: 45%;
      right: auto;
      top: auto;

      * {
        font-size: small;
      }
    }

    .download {
      position: absolute;
      bottom: 10px;
      right: auto;
      left: 10px;
      z-index: 9999;
      border: 3px solid rgba(210, 190, 167, 0.8);
      border-radius: 3px;

      a {
        width: fit-content;
        color: white;
        background-color: $accent;
        display: block;
        padding: 3px 10px 3px 10px;
        font-size: small;
        border-radius: 2px;
        cursor: pointer;
      }
      a:hover {
        background-color: $accent;
      }
      i {
        margin-right: 5px;
        font-size: smaller;
      }
    }

    .base_selector {
      position: absolute;
      right: 10px;
      left: auto;
      bottom: 104px;
      top: auto;
      background-color: $accent;
      border: 3px solid rgba(210, 190, 167, 0.8);
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;

      .fa-map {
        padding: 4px 2px 4px;
        color: white;
      }
    }

    .base_selector:hover {
      background-color: $accent;
    }

    .layers {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: white, $alpha: 0.8);
      border: 1px solid $accent;
      z-index: 99999;
      display: flex;
      align-items: center;
      align-content: center;

      .cont {
        width: 100%;
        display: block;
      }

      h3 {
        text-align: center;
        color: $accent;
      }

      .fa-close {
        display: block;
        color: $accent;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        margin: 0 10px 0 auto !important;
        font-size: large;
      }
      .fa-close:hover {
        color: #74465c;
      }

      .basemaps {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        padding: 1em;
        width: 100%;

        p {
          padding: 4px;
          text-align: center;
          font-size: small;
        }

        .active {
          border: 2px solid $accent;
          background-color: white;
          border-radius: 8px;
          cursor: pointer;
          overflow: hidden;
          img {
            max-width: 100%;
            width: 100%;
            height: 120px;
            object-fit: cover;
          }
        }

        .item {
          background-color: white;
          border-radius: 8px;
          cursor: pointer;
          border: 2px solid transparent;
          overflow: hidden;

          img {
            max-width: 100%;
            width: 100%;
            height: 120px;
            object-fit: cover;
          }
        }

        .item:hover {
          border: 1px solid $accent;
        }
      }
    }

    .select {
      margin: 10px 0 10px 0;

      h4 {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }

      select {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      select:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .items {
      width: 100%;

      .link {
        max-width: 85%;
        width: 100%;
        margin: 1em auto 1em auto;

        h4 {
          text-align: center;
          padding: 8px;
          color: $primary;
          width: 100%;
        }

        input {
          width: 100%;
          padding: 1em;
          background-color: transparent;
          border-radius: 5px;
          outline: none;
          box-shadow: 1px 1px 5px #60606030;
          border: 1px solid rgba($color: $dark, $alpha: 0.5);
          margin: 1em 0 0 0;
        }
      }

      .exp {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        min-width: 150px;
        width: fit-content;
        cursor: pointer;
        padding: 5px 10px 5px 1em;

        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
        }

        p {
          line-height: 24px;
          text-align: left !important;
        }
      }
      .exp:hover {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
      }
      h3 {
        text-align: center;
        padding: 8px;
        color: $primary;
        width: 100%;
      }

      h4 {
        font-size: 10pt;
        text-align: center;
        padding: 10px 0 10px 0;
      }

      img {
        height: 44px;
        width: 44px;
        object-fit: contain;
      }

      .imgSelected {
        border: 2px solid $primary;
      }

      .list {
        padding: 1em;
      }

      .item {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        color: $dark;
        margin: 16px 0 16px 0;
      }
    }

    // .data_popup {
    //   position: absolute;
    //   left: 10px;
    //   top: 50px;
    //   bottom: 50px;
    //   overflow: hidden;
    //   z-index: 999;
    //   background-color: $bg2;
    //   border-radius: 5px;
    //   max-width: 250px;
    //   width: 100%;
    //   height: auto;

    //   .workspace_item {
    //       margin-bottom: 5px;

    //       .top {
    //         display: grid;
    //         grid-template-columns: 1fr auto;
    //         padding: 5px;
    //         background-color: wheat;
    //         border-radius: 2px;
    //         cursor: pointer;

    //         p {
    //           font-size: small;
    //           display: block;
    //           margin: auto;
    //           text-align: left;
    //           width: 100%;
    //         }

    //         i {
    //           font-size: medium;
    //           display: block;
    //           margin: auto;
    //         }
    //       }

    //       .top:hover {
    //         background-color: whitesmoke;
    //       }

    //       .item {
    //         cursor: pointer;
    //         display: grid;
    //         grid-template-columns: auto 1fr;
    //         gap: 10px;
    //         padding: 5px;
    //         border-radius: 2px;
    //         margin: 3px;

    //         p {
    //           font-size: small;
    //           margin: auto;
    //           text-align: left;
    //           width: 100%;
    //           clear: both;
    //           display: inline-block;
    //           overflow: hidden;
    //           white-space: nowrap;
    //         }

    //         i {
    //           font-size: small;
    //           display: block;
    //           margin: auto;
    //           color: $accent;
    //         }
    //       }

    //       .item:hover {
    //         background-color: wheat;
    //       }
    //     }

    //   .bar {
    //     background-color: $accent;
    //     display: grid;
    //     grid-template-columns: repeat(3, 1fr);
    //     border-radius: 5px 5px 0 0;

    //     p {
    //       font-size: small;
    //       padding: 4px 6px 4px 6px !important;
    //       color: white;
    //       text-align: center;
    //       cursor: pointer;
    //     }

    //     p:hover {
    //       color: $accent;
    //       background-color: $bg2;
    //       border-radius: 5px 5px 0 0;
    //     }

    //     .active {
    //       color: $accent;
    //       background-color: $bg2;
    //       cursor: pointer;
    //       border-radius: 5px 5px 0 0;
    //     }
    //   }

    //   .dcont {
    //     padding: 1em;
    //   }

    //   .basic_styler {
    //     padding: 10px;
    //     position: relative;

    //     .query {
    //       width: 100% !important;
    //       height: fit-content !important;
    //       padding: 4px !important;
    //       font-size: small;
    //       margin: auto;
    //       display: block;
    //       border: 1px solid $accent !important;
    //       border-radius: 5px;
    //       outline: none;
    //     }
    //     button {
    //       padding: 6px 1em 6px 1em;
    //       background-color: $accent;
    //       color: white;
    //       margin: 12px auto 0 auto;
    //       border: none;
    //       border-radius: 5px;
    //       cursor: pointer;
    //       display: block;
    //       min-width: 100px;

    //       .fa-check {
    //         color: white;
    //       }
    //     }

    //     .range_popup {
    //       position: absolute;
    //       top: -24px;
    //       bottom: auto;
    //       left: 240px;
    //       right: 0;
    //       display: flex;
    //       align-items: center;
    //       width: 100%;
    //       max-width: 250px;

    //       .container {
    //         background-color: white;
    //         border-radius: 5px;
    //         padding: 10px;
    //         width: 100%;
    //         margin: auto 1em auto 1em;
    //         box-shadow: 3px 3px 5px #60606040;

    //         h3 {
    //           font-weight: 600 !important;
    //         }

    //         button {
    //           padding: 6px 1em 6px 1em;
    //           background-color: $accent;
    //           color: white;
    //           margin: 12px auto 0 auto;
    //           border: none;
    //           border-radius: 5px;
    //           cursor: pointer;
    //           display: block;
    //           min-width: 100px;

    //           .fa-check {
    //             color: white;
    //           }
    //         }

    //         .columns {
    //           display: grid;
    //           grid-template-columns: 1fr 0.5fr 0.5fr;
    //           gap: 5px;
    //           margin-bottom: 8px;

    //           input {
    //             width: 100%;
    //             padding: 2px !important;
    //             font-size: small;
    //           }
    //         }
    //       }
    //     }

    //     .buttons {
    //       width: fit-content;
    //       margin: 5px 0 5px auto;
    //       display: grid;
    //       grid-template-columns: repeat(2, auto);
    //       gap: 10px;

    //       .fa {
    //         cursor: pointer;
    //         color: $accent;
    //         font-size: medium;
    //         padding: 3px;
    //       }
    //       .fa:hover {
    //         color: #74465c;
    //       }
    //     }

    //     input[type="number"] {
    //       padding: 8px 10px 8px 10px !important;
    //       border: 1px solid $accent !important;
    //       border-radius: 5px;
    //       color: $accent !important;
    //       min-width: 44px;
    //       text-align: center;
    //     }

    //     p {
    //       color: $accent;
    //       font-size: small;
    //     }

    //     .classes {
    //       max-height: 28vh;
    //       height: 100%;
    //       overflow-y: auto;
    //     }

    //     .fa {
    //       color: $accent;
    //       padding: 3px;
    //     }

    //     .row {
    //       display: grid;
    //       grid-template-columns: auto 1fr auto auto;
    //       gap: 10px;
    //       margin: 5px 0 5px 0;

    //       input {
    //         width: 16px;
    //         height: 16px;
    //         border: none;
    //         cursor: pointer;
    //         display: block;
    //         margin: auto;
    //       }

    //       p {
    //         font-size: small;
    //         display: block;
    //         width: 100%;
    //         margin: auto;
    //       }

    //       select {
    //         padding: 3px;
    //         background-color: $bg2;
    //         border: 1px solid $accent;
    //         border-radius: 3px;
    //         cursor: pointer;
    //         font-size: smaller;
    //         color: $accent;
    //       }
    //     }
    //   }

    //   .split {
    //     display: grid;
    //     grid-template-columns: auto auto !important;

    //     input {
    //       width: 24px;
    //     }
    //   }

    //   h3 {
    //     font-size: small;
    //     font-weight: 500;
    //     color: black;
    //     margin: 7px 0 5px 0;
    //   }

    //   br {
    //     height: 1px !important;
    //     padding: 0;
    //     margin: 0;
    //   }

    //   hr {
    //     color: #74465c;
    //     margin: 10px 0 5px 0;
    //   }

    //   .fa-close {
    //     display: block;
    //     margin: 0 0 0 auto;
    //     color: $accent;
    //     cursor: pointer;
    //     width: fit-content;
    //   }
    //   .fa-close:hover {
    //     color: #74465c;
    //   }
    // }

    .data_popup {
      position: absolute;
      left: 10px;
      top: 50px;
      bottom: 50px;
      overflow: hidden;
      z-index: 999;
      background-color: $bg2;
      border-radius: 5px;
      max-width: 250px;
      width: 100%;
      height: auto;

      .dcont {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 10px;
        position: relative;

        .fa-close {
          position: absolute;
          right: 10px;
          bottom: 10px;
          display: block;
          margin: 0 0 0 auto;
          color: $accent;
          cursor: pointer;
          width: fit-content;
        }

        .fa-close:hover {
          color: #74465c;
        }
      }
      overflow-y: auto;

      .workspace_item {
        margin-bottom: 5px;

        .top {
          display: grid;
          grid-template-columns: 1fr auto;
          padding: 5px;
          background-color: wheat;
          border-radius: 2px;
          cursor: pointer;

          p {
            font-size: small;
            display: block;
            margin: auto;
            text-align: left;
            width: 100%;
          }

          i {
            font-size: medium;
            display: block;
            margin: auto;
          }
        }

        .top:hover {
          background-color: whitesmoke;
        }

        .item {
          cursor: pointer;
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          padding: 5px;
          border-radius: 2px;
          margin: 3px;
          width: 90%;
          p {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-size: small;
            margin: auto;
            text-align: left;
            width: 100%;
            clear: both;
            display: inline-block;
            overflow: hidden;
            white-space: wrap;
          }

          i {
            font-size: small;
            display: block;
            margin: auto;
            color: $accent;
          }
        }

        .item:hover {
          background-color: wheat;
        }
      }

      .bar {
        background-color: $accent;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-radius: 5px 5px 0 0;

        p {
          font-size: small;
          padding: 4px 6px 4px 6px !important;
          color: white;
          text-align: center;
          cursor: pointer;
        }

        p:hover {
          color: $accent;
          background-color: $bg2;
          border-radius: 5px 5px 0 0;
        }

        .active {
          color: $accent;
          background-color: $bg2;
          cursor: pointer;
          border-radius: 5px 5px 0 0;
        }
      }

      .dat_cont {
        padding: 1em;
      }

      .basic_styler {
        padding: 10px;
        position: relative;

        .query {
          width: 100% !important;
          height: fit-content !important;
          padding: 4px !important;
          font-size: small;
          margin: auto;
          display: block;
          border: 1px solid $accent !important;
          border-radius: 5px;
          outline: none;
        }

        button {
          padding: 6px 1em 6px 1em;
          background-color: $accent;
          color: white;
          margin: 12px auto 0 auto;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          display: block;
          min-width: 100px;

          .fa-check {
            color: white;
          }
        }

        .range_popup {
          position: absolute;
          top: -24px;
          bottom: auto;
          left: 240px;
          right: 0;
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 250px;

          .container {
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            width: 100%;
            margin: auto 1em auto 1em;
            box-shadow: 3px 3px 5px #60606040;

            h3 {
              font-weight: 600 !important;
            }

            button {
              padding: 6px 1em 6px 1em;
              background-color: $accent;
              color: white;
              margin: 12px auto 0 auto;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              display: block;
              min-width: 100px;

              .fa-check {
                color: white;
              }
            }

            .columns {
              display: grid;
              grid-template-columns: 1fr 0.5fr 0.5fr;
              gap: 5px;
              margin-bottom: 8px;

              input {
                width: 100%;
                padding: 2px !important;
                font-size: small;
              }
            }
          }
        }

        .buttons {
          width: fit-content;
          margin: 5px 0 5px auto;
          display: grid;
          grid-template-columns: repeat(2, auto);
          gap: 10px;

          .fa {
            cursor: pointer;
            color: $accent;
            font-size: medium;
            padding: 3px;
          }

          .fa:hover {
            color: #74465c;
          }
        }

        input[type="number"] {
          padding: 8px 10px 8px 10px !important;
          border: 1px solid $accent !important;
          border-radius: 5px;
          color: $accent !important;
          min-width: 44px;
          text-align: center;
        }

        p {
          color: $accent;
          font-size: small;
        }

        .classes {
          max-height: 28vh;
          height: 100%;
          overflow-y: auto;
        }

        .fa {
          color: $accent;
          padding: 3px;
        }

        .row {
          display: grid;
          grid-template-columns: auto 1fr auto auto;
          gap: 10px;
          margin: 5px 0 5px 0;

          input {
            width: 16px;
            height: 16px;
            border: none;
            cursor: pointer;
            display: block;
            margin: auto;
          }

          p {
            font-size: small;
            display: block;
            width: 100%;
            margin: auto;
          }

          select {
            padding: 3px;
            background-color: $bg2;
            border: 1px solid $accent;
            border-radius: 3px;
            cursor: pointer;
            font-size: smaller;
            color: $accent;
          }
        }
      }

      .split {
        display: grid;
        grid-template-columns: auto auto !important;

        input {
          width: 24px;
        }
      }

      h3 {
        font-size: small;
        font-weight: 500;
        color: black;
        margin: 7px 0 5px 0;
      }

      br {
        height: 1px !important;
        padding: 0;
        margin: 0;
      }

      hr {
        color: #74465c;
        margin: 5px 0 10px 0;
      }
    }
  }

  .cont {
    padding: 2em;
    background-color: white;
    border-radius: 5px;
    margin: 1em;

    .input-map {
      margin: 10px 0 10px 0;

      h4 {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }
      textarea {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      textarea:focus {
        border-bottom: 2px solid $primary;
      }
      input {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .div2equal {
      gap: 2em !important;
      margin: 2em 0 2em 0;
    }

    .img {
      position: relative;
      width: 100%;
      height: calc(25vw * 0.6);

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: center;
        border-radius: 5px;
      }

      input {
        display: none;
      }

      i {
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        left: 45%;
        position: absolute;
        bottom: -20px;
        cursor: pointer;
        background-color: $accent;
        border-radius: 5px;
      }
      i:hover {
        background-color: $dark;
        color: $accent;
      }

      @include maxwidth(tablet) {
        height: 200px !important;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 1.3;
      max-width: 80%;
      color: $primary;
      text-align: center;
      display: block;
      margin: 2em auto 10px auto;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
      max-width: 60%;
      display: block;
      margin: 10px auto 10px auto;
      text-align: center;
    }
  }

  @include maxwidth(mobile) {
    margin-top: 1px !important;
    padding-bottom: 18% !important;
    .model {
      .container {
        max-width: 80vw !important;
      }
    }
    .map {
      .leaflet-container {
        height: 60vh !important;
      }
      .sidepanel {
        .Popup {
          height: 44vh !important;
          width: 90vw !important;
        }
      }
    }
  }

  @include maxwidth(tablet) {
    margin-top: 1px !important;
    padding-bottom: 18% !important;
    .model {
      .container {
        max-width: 80vw !important;
      }
    }
    .map {
      .leaflet-container {
        height: 60vh !important;
      }
      .sidepanel {
        .Popup {
          height: 44vh !important;
          width: 50% !important;
        }
      }
    }
  }
}
