.aboutPage {
  height: 100vh;
  overflow-y: auto;

  .section-1 {
    position: relative;
    width: 92.5%;
    margin: 0 0 0 auto;
    height: 80vh;
    display: flex;
    flex-direction: row;

    .text-main {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 10% 5rem 2rem 0;

      p {
        font-size: medium;
        line-height: 1.3;
      }

      h1 {
        font-size: 2.5rem;
        margin: 15px 0 15px 0;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(120deg, #a20c0c 0%, #0e0e80 100%);
      }
      h2 {
        color: rgb(33, 33, 33);
        margin: 5px 0 15px 0;
      }
      a {
        position: relative;
        display: flex;
        font-size: large;
        text-decoration: none;
        color: #000;
        margin: 25px;
        padding: 20px;
        width: 300px;
        text-align: center;
        justify-content: center;
        // * hover-border-2 */
        ::before,
        ::after {
          position: absolute;
          content: "";
          width: 10%;
          height: 25%;
          transition: 0.35s;
        }
        ::before {
          bottom: 0;
          left: 0;
          border-left: 1px solid rgb(0, 0, 61);
          border-bottom: 1px solid rgb(0, 0, 61);
        }
        ::after {
          top: 0;
          right: 0;
          border-right: 1px solid rgb(0, 0, 61);
          border-top: 1px solid rgb(0, 0, 61);
        }
        :hover::before,
        :hover::after {
          width: 99%;
          height: 99%;
        }
        :hover {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(120deg, #a20c0c 0%, #0e0e80 100%);
        }
      }
    }
    .img-hero {
      width: 100%;
      height: 100%;
      border-radius: 0 0 0 65%;
      background-size: cover;
      background-position: center;
    }
  }

  .section-2 {
    position: relative;
    max-width: 92.5%;
    width: 100%;
    margin: 0 auto 0 0;
    height: fit-content;
    text-align: center;
    padding-top: 5em;
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 50px;

      h2 {
        font-size: x-large;
        margin: 15px 0 15px 0;
        color: #a20c0c;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(120deg, #a20c0c 0%, #0e0e80 100%);
      }
      p {
        font-size: medium;
        line-height: 1.5;
      }
    }

    .img-hero {
      position: relative;
      margin: 10px auto;
      width: 85%;
      height: 50vh;
      background-size: cover;
      background-position: center;

      ::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        width: 102%;
        height: 102%;
        background: linear-gradient(120deg, #a20c0c 0%, #0e0e80 100%);
        z-index: -1;
        transition: 300ms;
      }
    }

    @include maxwidth(mobile) {
      max-width: 85% !important;
      margin: 0 auto 0 auto !important;

      .text {
        padding-left: 0 !important;
        margin: 1em auto 0 auto !important;
      }
    }
  }

  .section-3 {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    padding-top: 100px;

    h2 {
      margin: 1em 7.5% 1em 7.5%;
      font-size: x-large;
      color: #a20c0c;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(120deg, #a20c0c 22%, #0e0e80 100%);
    }

    .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 80%;
      margin: auto;

      div {
        width: 45%;
        height: 35vh;
        margin: 40px auto;
        padding: 30px;
        border: 1px solid rgb(162, 12, 12);
        transition: 1s ease;
        h4 {
          font-size: x-large;
          margin: 10px;
          color: liner;
          transition: 1s ease;
        }
        p {
          font-size: medium;
          line-height: 1.5;
        }
        :hover h4 {
          color: rgb(162, 12, 12);
        }
        :nth-child(2):hover h4,
        :nth-child(3):hover h4 {
          color: rgb(18, 18, 182);
        }
      }
    }

    @include maxwidth(mobile) {
      max-width: 85% !important;
      margin: 0 auto 0 auto !important;

      h2 {
        margin: 0 3.5% 1em 3.5% !important;
      }

      .container {
        width: 100% !important;
      }
    }
  }
  .section-4 {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;

    .vision {
      width: 85%;
      margin: 1em auto 4em auto;

      h2 {
        margin: 2.5em 7.5% 2em 7.5%;
        font-size: x-large;
        color: #a20c0c;
        text-transform: capitalize;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(120deg, #a20c0c 22%, #0e0e80 100%);
      }
      p {
        text-align: left;
        margin: 1em 0 1em 0;
        line-height: 1.5;
        font-size: medium;
      }
      h3 {
        margin: 2.5em 7.5% 2em 7.5%;
        font-size: x-large;
        color: #a20c0c;
        text-transform: capitalize;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(120deg, #a20c0c 22%, #0e0e80 100%);
      }
      ul {
        list-style: upper-roman;
        text-align: left;
        width: 85%;
        margin: 1em auto 1em auto;
        li {
          margin: 10px 0 10px 0;
        }
      }
    }
  }
}

@include maxwidth(mobile) {
  .aboutPage {
    .section-1 {
      flex-direction: column;
      .img-hero {
        display: none;
      }
      .text-main {
        a {
          font-size: 1rem;
          width: 200px;
          margin: 40px auto;
        }
      }
    }
    .section-2 {
      padding-top: 0;
      flex-direction: column;
    }
    .section-3 {
      h2 {
        font-size: 1.5rem;
      }
      .container {
        flex-direction: column;
        div {
          width: 85%;
          height: auto;
        }
      }
    }
    .section-4 {
      .vision {
        h2 {
          font-size: large;
        }
        width: 85%;
      }
    }
  }
}

@include maxwidth(tablet) {
  .aboutPage {
    .section-1 {
      height: 50vh;
      margin-bottom: 5em;
    }
    .section-2 {
      height: 40vh;
      margin-bottom: 5em;

      .img-hero {
        height: 40vh;
      }
    }
    .section-3 {
      h2 {
        font-size: 1.5rem;
      }
      .container {
        flex-direction: column;
        div {
          width: 85%;
          height: auto;
        }
      }
    }
    .section-4 {
      .vision {
        h2 {
          font-size: large;
        }
        width: 85%;
      }
    }
  }
}
