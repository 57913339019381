.ContactUsPage {
  height: 100vh;
  overflow-y: auto;

  .c_hero {
    width: 100%;
    height: 50vh;
    position: relative;

    .cf {
      position: relative;
      width: 100%;
      height: 100%;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;

      .overlay {
        width: 100%;
        height: 50vh;
        background-color: rgba($color: #000000, $alpha: 0.7);
        color: white;
        display: grid;
        grid-template-rows: auto 1fr auto;

        .msg {
          display: flex;
          align-items: center;
          height: 50vh;
          h1 {
            text-align: center;
            color: white;
            width: fit-content;
            display: block;
            margin: auto;
            //font-size: 40px;
          }
        }
      }
    }
  }

  .contactsContent {
    .userMessage {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 80%;
      margin: 60px auto;
      gap: 5em;

      .message {
        h2 {
          font-size: x-large;
          text-align: center;
          margin: 2em auto 1em auto;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(120deg, #a20c0c 0%, #0e0e80 100%);
        }
        h5 {
          font-size: small;
          text-align: center;
          line-height: 1.5;
          color: #a20c0c;
        }
        p {
          font-size: medium;
          line-height: 1.5;
        }
      }

      .form {
        width: 90%;
        margin: auto;
        text-align: center;

        .input {
          margin: 10px 0 10px 0;

          label {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
            text-align: left;
          }
          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
          textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          textarea:focus {
            border-bottom: 2px solid $primary;
          }
        }
        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 12px;
          min-width: 250px;
          width: fit-content;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
      }

      @include maxwidth(mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
