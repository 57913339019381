.footer {
  position: relative;
  background-image: url('https://images.unsplash.com/photo-1613457231357-a5db3bc5bd81?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870');
  background-position: center;
  background-size: cover;
  text-align: center;
  
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 23, 108, 0.85);
    z-index: 0;
  }
  
  .copyright{
    position: absolute;
    left:50%;
    bottom: 10px;
    transform: translateX(-50%);
    font-size: 0.8rem;
    color: whitesmoke;
  }

  .container {
    position: relative;
    width: 85vw;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 4em;
    color: white;
    padding: 2em 0 2em 0;
    z-index: 10;

    hr {
      display: none;
    }

    .hd {
      display: grid;
      grid-template-columns: 1fr auto;

      h3 {
        font-size: 11pt;
        margin-bottom: 0.5em;
        letter-spacing: 1px;
      }

      i {
        font-size: 7pt;
        display: none;
      }
    }

    .section{
      .social{
        img{
          width: 30px;
          margin: 10px;
          cursor: pointer;
        }
      }
    }

  
    p{
      font-size: 10pt;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      letter-spacing: 1px;
      transition: 300ms ease;
    }
    .info {
      cursor: pointer;
      color: white;
      text-decoration: none;
    }

    .info:hover {
      color: $accent;
    }
  }

  @include maxwidth(mobile) {
    .container {
      grid-template-columns: 1fr !important;
      gap: 1em !important;
      i,
      hr {
        display: block !important;
      }
    }
  }

  @include maxwidth(tablet) {
    .container {
      grid-template-columns: repeat(2,1fr) !important;
      gap: 3em !important;
      i,
      hr {
        display: block !important;
      }
    }
  }
}
