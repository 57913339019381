@import "./_1_variables";
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  margin-top: 1.5em;
  background-color: transparent;

  .container {
    max-width: 85%;
    width: 100%;
    height: fit-content;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr auto auto;

    .logo {
      width: 80%;
      display: grid;
      grid-template-columns: auto 1fr;
      cursor: pointer;

      img {
        max-height: 32px;
        height: 100%;
        object-fit: contain;
      }

      h2 {
        color: white;
        font-size: large;
        line-height: 32px;
        margin-left: 10px;
        text-shadow: 1px 1px 2px black;
      }
    }

    .nav {
      display: grid;
      grid-template-columns: repeat(6, auto);
      gap: 10px;
      color: white;
      align-items: center;

      a {
        font-size: small;
        text-align: center;
        cursor: pointer;
        padding: 10px 1em 0 10px;
        width: 130px;

        height: 40px;
        border-radius: 5px;
        border: transparent solid 1px;
      }

      .underline {
        border-bottom: #fff solid 1px;
      }

      a:hover {
        border: #fff solid 1px;
      }

      .navlink {
        color: #ffffff;
        cursor: pointer;
      }
    }

    .nav2 {
      width: fit-content;
      margin-left: auto;
      .loginOut {
        margin-left: 20px;
        span {
          line-height: 12px;
        }
        .userOptions {
          position: absolute;
          visibility: hidden;
          background-color: whitesmoke;
          width: 150px;
          color: black;

          h5 {
            line-height: 24px;
            border-bottom: 1px solid gray;
            padding-left: 5px;
            cursor: pointer;
          }
          h5:hover {
            background-color: #030092;
            color: white;
          }
        }
        .showSettings {
          visibility: visible;
        }
      }

      button {
        position: relative;
        display: flex;
        justify-content: center;
        background-position: 0 0;
        color: $tertiarylight;
        line-height: 32px;
        border-radius: 12px;
        height: 32px;
        background: none;
        outline: none;
        border: none;
        min-width: 100px;
        font-size: 1rem;
        cursor: pointer;
        z-index: 2;
        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          background: linear-gradient(120deg, $secondary 0%, $accent 100%);
          z-index: -1;
        }
        &::after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          background-color: red;
          z-index: -1;
          opacity: 0;
          transition: 300ms ease-in-out;
        }
        &:hover::after {
          opacity: 1;
        }
      }
    }
  }

  .fa-bars {
    display: none !important;
    width: fit-content;
    margin-left: auto;
    display: block;
  }

  @include maxwidth(mobile) {
    .container {
      grid-template-columns: 1fr auto !important;
    }

    .nav,
    .nav2 {
      display: none !important;
    }

    .fa-bars {
      display: block !important;
      color: white;
      margin: auto;
    }

    .fa-bars:hover {
      color: $secondarylight;
    }
  }

  @include maxwidth(tablet) {
    width: 100vw !important;
    .logo {
      img {
        height: 32px;
        display: block;
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .nav,
    .nav2 {
      display: none !important;
    }

    .fa-bars {
      display: block !important;
      color: white;
      margin: auto;
      // background: black;
      backdrop-filter: blur(4px) saturate(150%);
    }

    .fa-bars:hover {
      color: $secondarylight;
    }
  }
}

.modalheader {
  z-index: 999999;
  background-color: #030092;
  height: fit-content;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 1px 1px 5px white;

  .container {
    padding: 1em 2em 1em 2em;
    height: 100%;
    margin-left: auto;
    position: relative;
    min-width: 50vw;
    width: 100%;

    .itemsLogo {
      display: flex;
      flex-direction: row;

      .logo {
        display: grid;
        grid-template-columns: auto 1fr auto;
        margin: 2em 0 2em 0;

        img {
          height: 44px;
          width: fit-content;
          object-fit: contain;
          display: block;
          cursor: pointer;
        }
        h2 {
          color: white;
          font-size: large;
          line-height: 44px;
          margin-left: 10px;
        }
      }
      i {
        padding: 10px;
        cursor: pointer;
        color: white;
        margin-left: 115px;
        margin-top: 35px;
      }
      i:hover {
        color: red;
      }
    }

    h3 {
      color: $tertiarylight;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16pt;
    }

    // .nav {
    //   gap: 1em;
    //   margin-top: auto;
    //   margin-bottom: auto;
    //   color: white;

    //   h4 {
    //     padding: 10px 10px 10px 50px;
    //     font-weight: 400;
    //     font-size: 14px;
    //     text-align: left;
    //     cursor: pointer;
    //     margin: 1em 0 1em 0;
    //   }

    //   h4:hover {
    //     color: $secondarylight;
    //   }

    //   button {
    //     background: none;
    //     color: $tertiarylight;
    //     line-height: 32px;
    //     border-radius: 5px;
    //     height: 32px;
    //     outline: none;
    //     border: none;
    //     max-width: 85%;
    //     width: 100%;
    //     margin: 10px auto 10px auto;
    //     display: block;
    //     border: 1px solid $tertiarylight;
    //     cursor: pointer;
    //   }

    //   button:hover {
    //     color: $accent;
    //     border: 1px solid $accent;
    //   }
    // }

    .nav2 {
      height: fit-content;
      margin: auto;
      position: absolute;
      bottom: 0;
      text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;

      h4 {
        color: white;
        padding: 1em;
        font-weight: 400;
        letter-spacing: 1px;
      }
    }
  }

  @include maxwidth(mobile) {
    width: 100vw !important;
  }
}

.login {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  z-index: 9999;
  animation: scale-in 400ms;

  @keyframes scale-in {
    0% {
      background-color: rgba($color: #000000, $alpha: 0);
      opacity: 0;
      transform: scale(0);
    }
    80% {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
    100% {
      background-color: rgba($color: #000000, $alpha: 0.5);
      opacity: 1;
      transform: scale(1);
    }
  }
  .container {
    background-color: white;
    border-radius: 8px;
    padding: 1em 2em 1em 2em;
    margin: 0 auto 0 auto;
    color: #000000;
    max-width: 400px;
    max-height: 70%;
    overflow-y: auto;
    width: 100%;

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      border-radius: 8px;
    }

    h3 {
      font-size: large;
      margin: 10px 0 10px 0;
      text-align: center;
    }

    .input-map {
      label {
        display: block;
        font-size: 14px;
        color: #74465c;
        margin: 10px 0 10px 0;
      }
      input {
        width: 100%;
        padding: 10px;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        box-shadow: 1px 1px 5px #60606030;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #74465c;
        font-size: 12px;
      }
    }

    p {
      font-size: 12px;
      text-align: center;
      margin: 10px 0 10px 0;

      span {
        color: #74465c;
        cursor: pointer;
      }
    }

    h4 {
      color: $accent;
      height: 14px;
      font-size: 12px;
      line-height: 14px;
      display: block;
      text-align: center;
      margin: 10px 0 10px 0;
      cursor: pointer;
    }

    .button {
      width: 100%;
      cursor: pointer;
    }
    .button:hover {
      background-color: rgba($color: #030092, $alpha: 0.7);
    }
  }
}
.model {
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;

  .forgotPassword {
    background-color: rgba($color: #ffffff, $alpha: 1);
    border-radius: 8px;
    max-width: 300px;
    width: 100%;
    padding: 1em 2em 1em 2em;
    margin: auto;

    h4 {
      color: black;
      padding: 5px;
      text-align: center;
      font-size: large;
      width: 100%;
    }

    p {
      color: #000000 !important;
      text-align: left;
      padding: 1px;
      font-size: small;
    }
    input {
      width: 100%;
      padding: 10px;
      background-color: transparent;
      border-radius: 5px;
      outline: none;
      box-shadow: 1px 1px 5px #60606030;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #74465c;
      font-size: 12px;
      margin-top: 15px;
    }

    button {
      background-color: #0c164f;
      min-width: 110px;
      width: fit-content;
      height: 40px;
      color: white;
      border-radius: 5px;
      padding: 10px;
      margin: 1em auto 1em auto;
      display: block;
    }
    .cancel {
      text-align: center;
      color: red;
      cursor: pointer;
      margin: 1em auto 1em auto;
    }
    .cancel:hover {
      color: #74465c;
    }
  }
}
